import { rgba } from 'colors';
import { IStyledProps } from 'styled-components';
import { BorderRadius, TextCapitalization } from 'models/ITheme';

export const FONT_FAMILY_BODY = (props: IStyledProps) => props.theme.site.typography.body;
export const FONT_FAMILY_TITLE = (props: IStyledProps) => props.theme.site.typography.headline;
export const ADMIN_FONT_FAMILY = (props: IStyledProps) => `${props.theme.admin.typography?.body}, Manrope, sans-serif`;

export const MAX_DROPDOWN_HEIGHT_PX = 173;
export const SPACING_X_SMALL_PX = 8;
export const SPACING_SMALL_PX = 12;
export const SPACING_MEDIUM_PX = 24;
export const SPACING_LARGE_PX = 32;
export const MIN_HERO_BANNER_HEIGHT_PX = 460;
export const MIN_HERO_BANNER_MOBILE_HEIGHT_PX = 590;
export const MAX_HERO_BANNER_MOBILE_HEIGHT_PX = 755;
export const MAX_HERO_BANNER_BLEED_PX = 215;
export const HERO_BANNER_WIDTH_PX = 1920;

export const MAX_DROPDOWN_HEIGHT = `${MAX_DROPDOWN_HEIGHT_PX}px`;
export const SPACING_X_SMALL = `${SPACING_X_SMALL_PX}px`;
export const SPACING_SMALL = `${SPACING_SMALL_PX}px`;
export const SPACING_MEDIUM = `${SPACING_MEDIUM_PX}px`;
export const SPACING_LARGE = `${SPACING_LARGE_PX}px`;

// TODO: We are stupid monkeys incapable of following simple rules
export const HEADER_HEIGHT = 89;
export const SIDEBAR_WIDTH = 320;
export const VIDEO_FOOTER_HEIGHT = 30;
export const VIDEO_SEEK_HEIGHT = 8;
export const MOBILE_VIDEO_FOOTER_HEIGHT = 50;
export const CHANNEL_SELECT_HEIGHT_PX = 90;
export const CHANNEL_SELECT_TOOLTIP_HEIGHT_PX = 20;
export const SIDEBAR_WIDTH_PX = 320;
export const SIDEBAR_EXTENDED_WIDTH_PX = 500;
export const MOBILE_MAX_WIDTH_PX = 896;
export const MOBILE_NAV_HEIGHT = 45;
export const MOBILE_NAV_HEIGHT_PX = `${MOBILE_NAV_HEIGHT}px`;
export const NAV_MODAL_WIDTH_PX = 450;
export const DESKTOP_MIN_WIDTH_PX = MOBILE_MAX_WIDTH_PX + 1;
export const DESKTOP_FHD_WIDTH_PX = 1920;
export const DESKTOP_2K_WIDTH_PX = 2560;
export const MOBILE_VIDEO_ASPECT_RATIO = 16 / 9;
export const COUNTDOWN_MESSAGE_CHARACTER_LIMIT = 45;
export const ADMIN_LINKS_ROW_HEIGHT_PX = 100;
export const TERMS_OF_USE_HEIGHT_PX = 80;
export const CURRENT_SIDEBAR_WIDTH_PX = <T extends IStyledProps & { sidebarWidth: number }>(props: T) => props.sidebarWidth;

export const TOOLBAR_HEIGHT_PX = 52;
export const TOOLBAR_HEIGHT = `${TOOLBAR_HEIGHT_PX}px`;
export const TAB_WIDTH_MD_PX = 345;
export const TAB_WIDTH_MD = `${TAB_WIDTH_MD_PX}px`;
export const TAB_PADDING_PX = 20;
export const TAB_PADDING = `${TAB_PADDING_PX}px`;

export const CHAT_PADDING_SMALL = 5;
export const CHAT_PADDING_LARGE = 10;
export const CHAT_TEXTAREA_HEIGHT = 40;
export const CHAT_TOOLBAR_HEIGHT = 40;
export const VIDEO_THUMBNAIL_HEIGHT_PX = 185;
export const ADMIN_MENU_WIDTH = 200;
export const ADMIN_MENU_ACTIVE_WIDTH = 350;
export const ADMIN_MENU_ACTIVE_PADDING = 60;
export const LIBRARY_MODAL_HEADER_FIRST_COLUMN_PADDING = 15;

export const CHANNEL_SELECT_HEIGHT_WITH_UNITS = `${CHANNEL_SELECT_HEIGHT_PX}px`;
export const CHANNEL_SELECT_TOOLTIP_HEIGHT_WITH_UNITS = `${CHANNEL_SELECT_TOOLTIP_HEIGHT_PX}px`;
export const SIDEBAR_WIDTH_WITH_UNITS = `${SIDEBAR_WIDTH_PX}px`;
export const SIDEBAR_EXTENDED_WIDTH_WITH_UNITS = `${SIDEBAR_EXTENDED_WIDTH_PX}px`;
export const MOBILE_MAX_WIDTH_WITH_UNITS = `${MOBILE_MAX_WIDTH_PX}px`;
export const DESKTOP_MIN_WIDTH_WITH_UNITS = `${DESKTOP_MIN_WIDTH_PX}px`;
export const DESKTOP_FHD_WIDTH_WITH_UNITS = `${DESKTOP_FHD_WIDTH_PX}px`;
export const DESKTOP_2K_WIDTH_WITH_UNITS = `${DESKTOP_2K_WIDTH_PX}px`;
export const HEADER_HEIGHT_WITH_UNITS = `${HEADER_HEIGHT}px`;
export const CHAT_PADDING_SMALL_WITH_UNITS = `${CHAT_PADDING_SMALL}px`;
export const CHAT_PADDING_LARGE_WITH_UNITS = `${CHAT_PADDING_LARGE}px`;
export const CHAT_TEXTAREA_HEIGHT_WITH_UNITS = `${CHAT_TEXTAREA_HEIGHT}px`;
export const CHAT_TOOLBAR_HEIGHT_WITH_UNITS = `${CHAT_TOOLBAR_HEIGHT}px`;
export const VIDEO_THUMBNAIL_HEIGHT_WITH_UNITS = `${VIDEO_THUMBNAIL_HEIGHT_PX}px`;
export const VIDEO_FOOTER_HEIGHT_WITH_UNITS = `${VIDEO_FOOTER_HEIGHT}px`;
export const VIDEO_SEEK_HEIGHT_WITH_UNITS = `${VIDEO_SEEK_HEIGHT}px`;
export const MOBILE_VIDEO_FOOTER_HEIGHT_WITH_UNITS = `${MOBILE_VIDEO_FOOTER_HEIGHT}px`;
export const ADMIN_MENU_WIDTH_WITH_UNITS = `${ADMIN_MENU_WIDTH}px`;
export const ADMIN_MENU_ACTIVE_WIDTH_WITH_UNITS = `${ADMIN_MENU_ACTIVE_WIDTH}px`;
export const ADMIN_MENU_ACTIVE_PADDING_WITH_UNITS = `${ADMIN_MENU_ACTIVE_PADDING}px`;
export const CURRENT_SIDEBAR_WIDTH_WITH_UNITS = <T extends IStyledProps & { sidebarWidth: number }>(props: T) => `${props.sidebarWidth}px`;

// transitions
export const ADMIN_EDIT_TRANSITION_DURATION = '500ms';

// third-party colors
export const AMAZON_ORANGE = '#f6c84a';
export const ADOBE_RED = '#ED1C24';
export const FACEBOOK_BLUE = '#3B5998';
export const INSTAGRAM_BLUE = '#517FA4';

export const SOUNDCLOUD_ORANGE = '#FF6600';
export const TWITCH_PURPLE = '#6441a5';
export const TWITTER_BLUE = '#4099FF';
export const YOUTUBE_RED = '#e52d27';
export const LIVE_INDICATOR_RED = '#FA0A0A';

export const CHROMA_KEY_COLOR = '#00FF00';
export const TRANSPARENT_COLOR = '#00000000';

export const MOBILE_LANDSCAPE_MEDIA = `(max-width: ${MOBILE_MAX_WIDTH_PX}px) and (min-aspect-ratio: 13/9)`;
export const MOBILE_PORTRAIT_MEDIA = `(min-width: 320px) and (max-height: ${MOBILE_MAX_WIDTH_PX}px) and (max-aspect-ratio: 9/13)`;
export const PORTRAIT_MEDIA = '(orientation: portrait)';

export const ACCENT_PRIMARY = (props: IStyledProps) => props.theme?.site.colors?.accentPrimary;
export const ACCENT_SECONDARY = (props: IStyledProps) => props.theme?.site.colors?.accentSecondary;
export const ACCENT_TERTIARY = (props: IStyledProps) => props.theme?.site.colors?.accentTertiary;

export const TEXT_100 = (props: IStyledProps) => props.theme.site.colors.text100;
export const TEXT_200 = (props: IStyledProps) => props.theme.site.colors.text200;
export const TEXT_300 = (props: IStyledProps) => props.theme.site.colors.text300;
export const TEXT_400 = (props: IStyledProps) => props.theme.site.colors.text400;
export const TEXT_500 = (props: IStyledProps) => props.theme.site.colors.text500;
export const TEXT_CONTRAST_ON_ACCENT_PRIMARY = (props: IStyledProps) => props.theme.site.colors.textContrastOnAccentPrimary;
export const TEXT_CONTRAST_ON_HIGHLIGHT_PRIMARY = (props: IStyledProps) => props.theme.site.colors.textContrastOnHighlightPrimary;

export const HIGHLIGHT_PRIMARY = (props: IStyledProps) => props.theme.site.colors.highlightPrimary;
export const HIGHLIGHT_SECONDARY = (props: IStyledProps) => props.theme.site.colors.highlightSecondary;
export const HIGHLIGHT_TERTIARY = (props: IStyledProps) => props.theme.site.colors.highlightTertiary;

export const SURFACE_1 = (props: IStyledProps) => props.theme.site.colors.surface1;
export const SURFACE_2 = (props: IStyledProps) => props.theme.site.colors.surface2;
export const SURFACE_3 = (props: IStyledProps) => props.theme.site.colors.surface3;
export const SURFACE_4 = (props: IStyledProps) => props.theme.site.colors.surface4;
export const SURFACE_5 = (props: IStyledProps) => props.theme.site.colors.surface5;
export const SURFACE_6 = (props: IStyledProps) => props.theme.site.colors.surface6;

export const ALERT_CONFIRMATION = (props: IStyledProps) => props.theme.site.colors.alertConfirmation;
export const ALERT_ERROR = (props: IStyledProps) => props.theme.site.colors.alertError;
export const ALERT_WARNING = (props: IStyledProps) => props.theme.site.colors.alertWarning;

export const TITLE_FONT_FAMILY = (props: IStyledProps) => `${props.theme.site.typography?.headline}, noto-sans-regular, sans-serif`;
export const BODY_FONT_FAMILY = (props: IStyledProps) => `${props.theme.site.typography?.body}, noto-sans-regular, sans-serif`;

export const BACKGROUND_ALT_ROW = (props: IStyledProps) => rgba(SURFACE_3(props), 0.1);
export const BUTTON_BORDER_RADIUS = (props: IStyledProps) => BORDER_RADIUS_MAP[props.theme.site.button.borderRadius];
export const BUTTON_TEXT_CAPITALIZATION = (props: IStyledProps) => TEXT_CAPITALIZATION_MAP[props.theme.site.button.textCapitalization];

// MAESTRO COLORS
export const MAESTRO_CYAN = '#63DCCC';
export const MAESTRO_GOLDEN_PURPLE = '#BE66FD';
export const MAESTRO_WHITE = '#FFFFFF';
export const MAESTRO_PURPLE = '#6E5FFB';
export const MAESTRO_YELLOW_LIGHT = '#EBFF80';
export const MAESTRO_BLACK = '#000000';
export const MAESTRO_BLUE_SKY = '#739BFF';
export const SKIN_COLOR = '#FDC068';

/**
 * Some page blocks can have a background color that is set by the admin.
 * There are theme colors, transparent and custom colors.
 * We can't save the hex value of the theme colors in the database since the theme may change.
 * So we save the color name and use this function to get the hex value from the theme.
 * And return the custom color if it's not a theme color.
 */
export const OPTIONAL_DYNAMIC_THEME_COLOR = (color: string) => (props: IStyledProps) => props.theme.site.colors[color] as string || color;

/* ADMIN CONSTANTS */

// ADMIN GENERAL CONSTANTS
export const ADMIN_BODY_BORDER_PX = 16;
export const ADMIN_BODY_BORDER = `${ADMIN_BODY_BORDER_PX}px`;
export const ADMIN_HEADER_HEIGHT_PX = 50;
export const ADMIN_HEADER_HEIGHT = `${ADMIN_HEADER_HEIGHT_PX}px`;

// TEXT OPACITY VARIABLES
export const NORMAL_OPACITY = 1;
export const PASSIVE_OPACITY = 0.7;
export const DISABLED_OPACITY = 0.3;

// ADMIN CONTENT CONSTANTS
export const ADMIN_TEXT_FONT_FAMILY_CONTENT_NORMAL = (props: IStyledProps) => `${props.theme.admin.typography.body}, Noto Sans, sans-serif`;
export const ADMIN_TEXT_FONT_FAMILY_CONTENT_BOLD = (props: IStyledProps) => `${props.theme.admin.typography.headline}, Noto Sans, sans-serif`;
export const ADMIN_TEXT_COLOR_CONTENT_DISABLED = rgba(
  MAESTRO_WHITE, DISABLED_OPACITY,
);

// ADMIN TITLE CONSTANTS
export const ADMIN_TEXT_FONT_FAMILY_TITLE_NORMAL = (props: IStyledProps) => `${props.theme.admin.typography.body}, Noto Sans, sans-serif`;
export const ADMIN_TEXT_COLOR_TITLE_PASSIVE = (props: IStyledProps) => rgba(ADMIN_TEXT_100(props), PASSIVE_OPACITY);
export const ADMIN_TEXT_COLOR_TITLE_DISABLED = (props: IStyledProps) => rgba(ADMIN_TEXT_100(props), DISABLED_OPACITY);

// ADMIN SUB TEXT BODY CONSTANTS
export const ADMIN_TEXT_COLOR_SUBBODY = rgba(MAESTRO_WHITE, 0.5);

// ADMIN INPUT CONSTANTS
export const ADMIN_INPUT_FONT_SIZE_PX = 16;
export const ADMIN_INPUT_FONT_SIZE = `${ADMIN_INPUT_FONT_SIZE_PX}px`;
export const ADMIN_INPUT_LINE_HEIGHT_PX = 24;
export const ADMIN_INPUT_LINE_HEIGHT = `${ADMIN_INPUT_LINE_HEIGHT_PX}px`;
export const ADMIN_INPUT_BORDER_PX = 2;
export const ADMIN_INPUT_BORDER_STYLE = 'solid';
export const ADMIN_INPUT_FONT_FAMILY_NORMAL = `${ADMIN_TEXT_FONT_FAMILY_CONTENT_NORMAL}, Noto Sans, sans-serif`;
export const ADMIN_INPUT_FONT_FAMILY_BOLD = `${ADMIN_TEXT_FONT_FAMILY_CONTENT_BOLD}, Noto Sans, sans-serif`;
export const ADMIN_INPUT_BORDER_COLOR = (props: any) => props.isDisabled ? ADMIN_TEXT_300 : ADMIN_SURFACE_5;
export const ADMIN_INPUT_BORDER = (props: IStyledProps) => `${ADMIN_INPUT_BORDER_PX}px ${ADMIN_INPUT_BORDER_STYLE} ${ADMIN_INPUT_BORDER_COLOR(props)}`;
export const ADMIN_INPUT_COLOR = (props: any) => props.isDisabled ? ADMIN_TEXT_COLOR_CONTENT_DISABLED : MAESTRO_WHITE;

// ADMIN LINK CONSTANTS
export const ADMIN_LINK_COLOR = rgba(MAESTRO_WHITE, PASSIVE_OPACITY);
export const ADMIN_LINK_COLOR_DISABLED = rgba(MAESTRO_WHITE, DISABLED_OPACITY);
export const ADMIN_LINK_FONT_FAMILY_NORMAL = `${ADMIN_TEXT_FONT_FAMILY_CONTENT_NORMAL}, Noto Sans, sans-serif`;

// ADMIN ICON CONSTANTS
export const ADMIN_ICON_SIZE_PX = 16;
export const ADMIN_ICON_SIZE = `${ADMIN_ICON_SIZE_PX}px`;

// ADMIN ACTION CONSTANTS
export const ADMIN_ACTION_COLOR = rgba(MAESTRO_WHITE, PASSIVE_OPACITY);
export const ADMIN_ACTION_COLOR_DISABLED = rgba(MAESTRO_WHITE, DISABLED_OPACITY);
export const ADMIN_ACTION_COLOR_HOVER = rgba(MAESTRO_WHITE, 1);
export const ADMIN_ACTION_FONT_FAMILY_BOLD = (props: IStyledProps) => `${props.theme.admin.typography.headline}, noto-sans-bold, Noto Sans, sans-serif`;

// ADMIN CODE CONSTANTS
export const ADMIN_CODE_COLOR = rgba(MAESTRO_WHITE, PASSIVE_OPACITY);
export const ADMIN_CODE_FONT_FAMILY_NORMAL = 'monospace, sans-serif';

export const ADMIN_LIBRARY_MODAL_WIDTH = 900;
export const ADMIN_LIBRARY_MODAL_WIDTH_WITH_UNITS = `${ADMIN_LIBRARY_MODAL_WIDTH}px`;
export const ADMIN_BAR_WIDTH_PX = 200;
export const ADMIN_BAR_WIDTH_WITH_UNITS = `${ADMIN_BAR_WIDTH_PX}px`;

//  PUBG STYLE CONSTANTS
export const PUBG_ICON_COLOR = '#9D2C2C';
export const PUBG_RANKING_COLOR = '#94949c';

export const ADMIN_BAR_WIDTH_SM_PX = 72;
export const ADMIN_BAR_WIDTH_SM_UNITS = `${ADMIN_BAR_WIDTH_SM_PX}px`;
export const ADMIN_BAR_WIDTH_LG_PX = 190;
export const ADMIN_BAR_WIDTH_LG_UNITS = `${ADMIN_BAR_WIDTH_LG_PX}px`;

const BORDER_RADIUS_MAP: Record<BorderRadius, '5px' | '0px' | '16px'> = {
  rounded: '5px',
  square: '0px',
  pill: '16px',
};

const TEXT_CAPITALIZATION_MAP: Record<TextCapitalization, 'lowercase' | 'none' | 'uppercase'> = {
  lowercase: 'lowercase',
  original: 'none',
  uppercase: 'uppercase',
};

/*
* Admin Theme Colors
*/
export const ADMIN_HIGHLIGHT_PRIMARY = (props: IStyledProps) => props.theme.admin.colors.highlightPrimary;
export const ADMIN_HIGHLIGHT_SECONDARY = (props: IStyledProps) => props.theme.admin.colors.highlightSecondary;
export const ADMIN_HIGHLIGHT_TERTIARY = (props: IStyledProps) => props.theme.admin.colors.highlightTertiary;
export const ADMIN_BUTTON_BORDER_RADIUS = (props: IStyledProps) => BORDER_RADIUS_MAP[props.theme.admin.button.borderRadius];
export const ADMIN_BUTTON_TEXT_CAPITALIZATION = (props: IStyledProps) => TEXT_CAPITALIZATION_MAP[props.theme.admin.button.textCapitalization];
export const ADMIN_ACCENT_PRIMARY = (props: IStyledProps) => props.theme.admin.colors.accentPrimary;
export const ADMIN_ACCENT_SECONDARY = (props: IStyledProps) => props.theme.admin.colors.accentSecondary;
export const ADMIN_ACCENT_TERTIARY = (props: IStyledProps) => props.theme.admin.colors.accentTertiary;
export const ADMIN_TEXT_CONTRAST_ON_ACCENT_PRIMARY = (props: IStyledProps) => props.theme.admin.colors.textContrastOnAccentPrimary;
export const ADMIN_TEXT_CONTRAST_ON_HIGHLIGHT_PRIMARY = (props: IStyledProps) => props.theme.admin.colors.textContrastOnHighlightPrimary;

export const ADMIN_ALERT_ERROR = (props: IStyledProps) => props.theme.admin.colors.alertError;
export const ADMIN_ALERT_CONFIRMATION = (props: IStyledProps) => props.theme.admin.colors.alertConfirmation;
export const ADMIN_ALERT_WARNING = (props: IStyledProps) => props.theme.admin.colors.alertWarning;

export const ADMIN_TEXT_100 = (props: IStyledProps) => props.theme.admin.colors.text100;
export const ADMIN_TEXT_200 = (props: IStyledProps) => props.theme.admin.colors.text200;
export const ADMIN_TEXT_300 = (props: IStyledProps) => props.theme.admin.colors.text300;
export const ADMIN_TEXT_400 = (props: IStyledProps) => props.theme.admin.colors.text400;
export const ADMIN_TEXT_500 = (props: IStyledProps) => props.theme.admin.colors.text500;

export const ADMIN_SURFACE_1 = (props: IStyledProps) => props.theme.admin.colors.surface1;
export const ADMIN_SURFACE_2 = (props: IStyledProps) => props.theme.admin.colors.surface2;
export const ADMIN_SURFACE_3 = (props: IStyledProps) => props.theme.admin.colors.surface3;
export const ADMIN_SURFACE_4 = (props: IStyledProps) => props.theme.admin.colors.surface4;
export const ADMIN_SURFACE_5 = (props: IStyledProps) => props.theme.admin.colors.surface5;
export const ADMIN_SURFACE_6 = (props: IStyledProps) => props.theme.admin.colors.surface6;
