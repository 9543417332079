import { css } from 'styled-components';
import {
  ADMIN_SURFACE_3,
  ADMIN_INPUT_COLOR,
  ADMIN_ALERT_WARNING,
  ADMIN_TEXT_COLOR_TITLE_PASSIVE,
  ADMIN_TEXT_200,
  ADMIN_SURFACE_1,
  ADMIN_SURFACE_4,
  ADMIN_TEXT_COLOR_CONTENT_DISABLED,
  ADMIN_ALERT_ERROR,
  ADMIN_TEXT_100,
  ADMIN_ALERT_CONFIRMATION,
  ALERT_ERROR,
} from 'style/constants';
import {
  FLEX_CENTER,
} from './misc';
import { ADMIN_TEXT_BODY_M_BOLD, ADMIN_TEXT_BODY_M_MEDIUM, ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export const ADMIN_INPUT_BOX_SHADOW = css`
  box-shadow: 0 5px 20px 0 rgba(0,0,0,0.2);
`;

export const InputVariantMap = {
  label: css`
    background: ${ADMIN_SURFACE_4};
    color: ${ADMIN_TEXT_100};
    padding: 0 18px;
  `,
  small: css`
    background: ${ADMIN_SURFACE_1};
    color: ${ADMIN_TEXT_100};
    border-radius: 3.5px;
    height: 35px;
  `,
};

export type InputVariant = keyof typeof InputVariantMap;

export interface InputStyleProps {
  inputVariant?: InputVariant;
}

/*  FIELDS */

// Field Titles
export const ADMIN_FIELD_PLACEHOLDER = css`
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  color: ${ADMIN_TEXT_200};
`;

export const ADMIN_FIELD_VALUE = css`
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  color: ${ADMIN_INPUT_COLOR};
`;

export const ADMIN_FIELD_TEXT_INPUT = css<{fullwidth?: boolean}>`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  background: ${ADMIN_SURFACE_3};
  ${({ fullwidth }) => fullwidth && 'width: 100%;'}
  color: ${ADMIN_TEXT_100};
  &:disabled {
    color: ${ADMIN_TEXT_COLOR_CONTENT_DISABLED};
  }
  border-radius: 5px;
  border: none;
  display: block;
  height: 40px;
  padding: 12px 10px 12px 15px;
  flex-grow: 1;
  :placeholder {
    ${ADMIN_TEXT_BODY_XS_MEDIUM}
    color: ${ADMIN_TEXT_100};
  }
  :focus {
    outline: none;
  }
`;

export const ADMIN_QUERY_TEXT_INPUT = css`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${ADMIN_TEXT_100};
  background: ${ADMIN_SURFACE_4};
  border: none;
  flex-grow: 1;
  padding: 0.5em;
  display: block;
  height: 50px;
  &::placeholder {
    ${ADMIN_FIELD_PLACEHOLDER}
  }
`;

export const ADMIN_TEXT_TITLE_FIELD_SUCCESS = css`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_ALERT_CONFIRMATION};
`;

export const ADMIN_TEXT_TITLE_FIELD_ERROR = css`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${ADMIN_ALERT_ERROR};
`;

export const ADMIN_TEXT_TITLE_FIELD_ALERT = css`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${ADMIN_ALERT_WARNING};
`;

export const ADMIN_CHECKBOX = css`
  ${FLEX_CENTER}
  min-width: 19px;
  min-height: 19px;
  cursor: pointer;
`;

export const INPUT_BORDER_ERROR = css<{ error: boolean }>`
  box-shadow: 0 0 0 ${props => props.error ? '1px' : '0px'} ${ALERT_ERROR};
`;

export const ADMIN_INPUT_BORDER_ERROR = css<{ error: boolean }>`
  box-shadow: 0 0 0 ${props => props.error ? '1px' : '0px'} ${ADMIN_ALERT_ERROR};
`;
